var render = function () {
  var _vm$getTotalPriceTrip, _vm$getTotalPriceTrip2, _vm$getTotalPriceTrip3, _vm$getTotalPriceTrip4, _vm$getTotalPriceTrip5, _vm$getTotalPriceTrip6;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    staticClass: "mb-50 mb-md-1 border-info-2",
    attrs: {
      "body-class": "p-50"
    }
  }, [_c('div', {
    staticClass: "d-flex-center flex-column flex-md-row justify-content-md-between mb-50"
  }, [_c('div', {
    staticClass: "bg-blue-gradient w-100 mb-25 mb-md-0 d-flex"
  }, [!_vm.isMobileView ? _c('div', {
    staticClass: "d-flex-center"
  }, [_c('b-alert', {
    staticClass: " p-1 mr-1 mb-0",
    staticStyle: {
      "width": "fit-content"
    },
    attrs: {
      "show": "",
      "variant": "primary"
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "DollarSignIcon",
      "size": "30"
    }
  })], 1)], 1) : _vm._e(), _c('div', [_c('h3', {
    staticClass: "text-airline"
  }, [_vm._v(" " + _vm._s(_vm.$t('flight.priceDetails')) + " ")]), _c('span', [_vm._v(" " + _vm._s(_vm.$t('flight.priceDetail')) + " ")])])]), _c('div', {
    class: "d-flex-center ".concat(_vm.isMobileView ? ' flex-wrap' : 'flex-nowrap', " gap-1")
  }, [_c('b-button', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal.modal-customer-service-fee",
      modifiers: {
        "modal-customer-service-fee": true
      }
    }],
    staticClass: "d-flex-center gap-1 flex-nowrap",
    class: _vm.isMobileView ? 'px-75' : '',
    attrs: {
      "size": "sm",
      "variant": "outline-primary"
    }
  }, [_c('span', {
    staticClass: "mr-25 align-middle text-nowrap"
  }, [_vm._v("Sửa phí dịch vụ")]), !_vm.isMobileView ? _c('feather-icon', {
    attrs: {
      "icon": "EditIcon",
      "size": "12"
    }
  }) : _vm._e()], 1), _c('b-button', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.v-warning.window",
      modifiers: {
        "hover": true,
        "v-warning": true,
        "window": true
      }
    }],
    staticClass: "p-75 rounded-circle",
    attrs: {
      "variant": "flat-warning",
      "title": "".concat(_vm.showServiceFee ? 'Ẩn' : 'Hiện', " ph\xED d\u1ECBch v\u1EE5")
    },
    on: {
      "click": function click($event) {
        _vm.showServiceFee = !_vm.showServiceFee;
      }
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "DollarSignIcon",
      "size": "16"
    }
  })], 1), _c('b-button', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.v-info.window",
      modifiers: {
        "hover": true,
        "v-info": true,
        "window": true
      }
    }],
    staticClass: "p-75 rounded-circle",
    attrs: {
      "variant": "flat-info",
      "title": "".concat(_vm.showBenefit ? 'Ẩn' : 'Hiện', " l\u1EE3i nhu\u1EADn")
    },
    on: {
      "click": function click($event) {
        _vm.showBenefit = !_vm.showBenefit;
      }
    }
  }, [_vm.showBenefit ? _c('feather-icon', {
    attrs: {
      "icon": "EyeIcon",
      "size": "16"
    }
  }) : _c('feather-icon', {
    attrs: {
      "icon": "EyeOffIcon",
      "size": "16"
    }
  })], 1), false ? _c('b-button', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.v-danger.window",
      modifiers: {
        "hover": true,
        "v-danger": true,
        "window": true
      }
    }],
    staticClass: "p-75 rounded-circle",
    attrs: {
      "variant": "flat-danger",
      "title": "".concat(_vm.showPromotion ? 'Ẩn' : 'Hiện', " chi\u1EBFt kh\u1EA5u v\xE0o gi\xE1")
    },
    on: {
      "click": function click($event) {
        _vm.showPromotion = !_vm.showPromotion;
      }
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "PercentIcon",
      "size": "16"
    }
  })], 1) : _vm._e()], 1)]), _c('ModalCustomServiceFee'), _c('b-card', {
    staticClass: "border",
    attrs: {
      "header-class": "py-1"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex"
        }, [_c('b', {
          staticClass: "font-medium-3"
        }, [_vm._v(" " + _vm._s(_vm.selectedTrip.map(function (t) {
          return "".concat(t.departure.IATACode, "-").concat(t.arrival.IATACode);
        }).join(', ')) + " ")])])];
      },
      proxy: true
    }])
  }, [_c('b-table-lite', {
    staticClass: "mb-1 rounded",
    attrs: {
      "bordered": "",
      "responsive": "",
      "thead-class": "text-dark font-weight-bolder text-nowrap",
      "fields": _vm.showServiceFee ? _vm.tripPriceColumns : _vm.tripPriceColumns.filter(function (item) {
        return item.key !== 'Service_Charge';
      }),
      "items": _vm.priceItems
    },
    scopedSlots: _vm._u([_vm._l(_vm.tripPriceColumns, function (column) {
      return {
        key: "head(".concat(column.key, ")"),
        fn: function fn(data) {
          return [_c('span', {
            key: column.label,
            staticClass: "text-dark"
          }, [_vm._v(" " + _vm._s(_vm.$t("flight.".concat(data.label))) + " ")])];
        }
      };
    }), {
      key: "cell(Passenger)",
      fn: function fn(data) {
        return [_c('h5', {
          staticClass: "text-dark align-middle text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm.$t("flight.".concat(data.item.paxType))) + " x" + _vm._s(data.item.numPaxType) + " ")])];
      }
    }, {
      key: "cell(Price)",
      fn: function fn(data) {
        return [data.item.discount || _vm.showPromotion && data.item.promotion ? _c('div', [_c('h6', {
          staticClass: "text-secondary text-right text-nowrap"
        }, [_c('del', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(_vm.formatCurrency(data.item.fare)))])]), _c('h5', {
          staticClass: "text-dark text-right text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm.showPromotion ? _vm.formatCurrency(data.item.fare - data.item.discount - data.item.promotion) : _vm.formatCurrency(data.item.fare - data.item.discount)) + " ")])]) : _c('h5', {
          staticClass: "text-dark text-right text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm.formatCurrency(data.item.fare)) + " ")])];
      }
    }, {
      key: "cell(Tax)",
      fn: function fn(data) {
        return [_c('h5', {
          staticClass: "text-dark text-right text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.showServiceFee ? data.item.totalSurcharge : data.item.totalSurcharge + data.item.serviceFee)) + " ")])];
      }
    }, {
      key: "cell(Service_Charge)",
      fn: function fn(data) {
        return [_c('h5', {
          staticClass: "text-dark text-right text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm.formatCurrency(data.item.serviceFee) || 0) + " ")])];
      }
    }, {
      key: "cell(Promotion)",
      fn: function fn(data) {
        return [_c('h5', {
          staticClass: "text-dark text-right text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm.formatCurrency(data.item.promotion)) + " ")])];
      }
    }, {
      key: "cell(Total)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "text-right text-nowrap"
        }, [data.item.discount || _vm.showPromotion && data.item.promotion ? _c('del', {
          staticClass: "text-danger"
        }, [_vm._v(" " + _vm._s(_vm.showPromotion ? _vm.formatCurrency(data.item.total + data.item.discount + data.item.promotion) : _vm.formatCurrency(data.item.total + data.item.discount)))]) : _vm._e(), _c('h5', {
          staticClass: "text-dark"
        }, [_vm._v(" " + _vm._s(_vm.showPromotion ? _vm.formatCurrency(data.item.total) : _vm.formatCurrency(data.item.total + data.item.promotion)) + " ")])])];
      }
    }], null, true)
  }), _c('div', {
    staticClass: "d-flex flex-wrap flex-md-nowrap",
    class: _vm.isMobileView ? 'flex-column' : 'justify-content-between'
  }, [_c('div', {
    staticClass: "flex-grow-1",
    class: _vm.isMobileView ? '' : 'mr-2'
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showBenefit,
      expression: "showBenefit"
    }]
  }, [_c('div', {
    staticClass: "d-flex-between",
    staticStyle: {
      "min-width": "250px"
    }
  }, [_c('strong', [_vm._v("Chiết khấu:")]), _c('strong', {
    staticClass: "font-medium-1"
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency((_vm$getTotalPriceTrip = (_vm$getTotalPriceTrip2 = _vm.getTotalPriceTripComputed) === null || _vm$getTotalPriceTrip2 === void 0 ? void 0 : _vm$getTotalPriceTrip2.promotions) !== null && _vm$getTotalPriceTrip !== void 0 ? _vm$getTotalPriceTrip : 0)) + " ")])]), _c('div', {
    staticClass: "d-flex-between",
    staticStyle: {
      "min-width": "250px"
    }
  }, [_c('div', [_c('strong', [_vm._v("Lợi nhuận:")]), _c('div', [_c('em', [_c('small', [_vm._v(" ("), !_vm.showPromotion ? _c('span', [_vm._v("Chiết khấu + ")]) : _vm._e(), _vm._v("Phí dịch vụ"), !_vm.selectedTrip[0].fareOptions.hideFee ? _c('span', [_vm._v(" - Phí xuất vé")]) : _vm._e(), _vm._v(") ")])])])]), _c('strong', {
    staticClass: "font-medium-1"
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency((_vm$getTotalPriceTrip3 = (_vm$getTotalPriceTrip4 = _vm.getTotalPriceTripComputed) === null || _vm$getTotalPriceTrip4 === void 0 ? void 0 : _vm$getTotalPriceTrip4.benefit) !== null && _vm$getTotalPriceTrip3 !== void 0 ? _vm$getTotalPriceTrip3 : 0)) + " ")])])])]), _c('div', {
    staticClass: " flex-grow-1",
    class: _vm.isMobileView ? 'mt-1' : 'ml-2'
  }, [_c('div', {
    staticClass: "d-flex-between"
  }, [_c('div', [_c('strong', {
    staticClass: "font-medium-2"
  }, [_vm._v(_vm._s(_vm.$t('flight.total')) + ":")])]), _c('strong', {
    staticClass: "font-medium-2"
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency((_vm$getTotalPriceTrip5 = (_vm$getTotalPriceTrip6 = _vm.getTotalPriceTripComputed) === null || _vm$getTotalPriceTrip6 === void 0 ? void 0 : _vm$getTotalPriceTrip6.total) !== null && _vm$getTotalPriceTrip5 !== void 0 ? _vm$getTotalPriceTrip5 : 0)) + " ")])]), _c('div', {
    staticClass: "text-primary font-small-3 text-right"
  }, [_c('i', [_vm._v("(" + _vm._s(_vm.$t('flight.fareText')) + ")")]), _vm.showBenefit ? _c('p', {
    staticClass: "mb-0"
  }, [_c('b', [_c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('i', [_vm._v(" " + _vm._s(_vm.$t('flight.noteDiscount')))])])]) : _vm._e()])])])], 1), _c('div', {
    staticClass: "d-flex-center"
  }, [_c('span', {
    staticClass: "text-dark font-weight-bolder"
  }, [_vm._v(" " + _vm._s(_vm.$t('flight.totalFare')) + ": "), _c('span', {
    staticClass: "text-warning font-medium-5"
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.getTotalPriceTicket(_vm.selectedTrip))) + " ")])])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }